// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // // apiUrl: 'https://sandbox.fsf.orbo.ai',
  apiUrl: window.location.protocol + '//' + window.location.host,
  //  apiUrl:'http://0.0.0:3000',
  // apiUrl: 'https://demo.fsf.orbo.ai',
  clientKey: 'G7E3JD8H369D7D0CCE2002101AA32hf5EA4078A182KS491C7452487195B801D63JS5'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
